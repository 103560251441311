<template>
  <a-popover
      v-model="visible"
      trigger="click"
      placement="rightTop"
      :get-popup-container="(triggerNode) => triggerNode.parentNode">
      <template slot="title">
        <span class="txt-uppercase txt-font-din-medium txt-10">{{ $t('components.titles.defineFieldsForDuplicationSearch') }}</span>
      </template>
      <template slot="content">
        <div class="flex justify-space-between flex-column">
          <div class="duplication-check-content">
            <a-input v-model.trim="search" :placeholder="$t('components.description.findAField')" allow-clear class="mb-10 txt-font-din-regular" @change="onSearch">
              <a-icon slot="prefix" type="search"/>
            </a-input>
            <div>
              <a-button type="link" class="txt-orange pl-0 pr-0" @click="onCheckAll">
                {{ $t('components.description.selectAll') }}
              </a-button>
              <span class="txt-orange ml-10 mr-10">-</span>
              <a-button type="link" class="txt-orange pl-0 pr-0" @click="onClearAll">
                {{ $t('values.clear') }}
              </a-button>
            </div>
            <a-checkbox-group v-model="selectedColumns">
              <div class="column-grid">
                <a-checkbox v-for="(column, i) in filteredColumns" :key="column" :value="column">
                  {{ (filteredColumnNames[i]).toUpperCase() }}
                </a-checkbox>
              </div>
            </a-checkbox-group>
          </div>
          <div class="flex justify-space-between align-center mb-10 mt-10">
            <a-button class="w101 txt-font-din-medium" type="primary" ghost @click="visible = false">
              {{ $t('values.cancel') }}
            </a-button>
            <a-button class="w101 txt-font-din-medium" type="primary" :disabled="selectedColumns.length < 1" @click="applyCheck">
              {{ $t('values.ok') }}
            </a-button>
          </div>
        </div>
      </template>
      <a-button class="txt-bold txt-black txt-font-din-medium mr-20"
        :disabled="count <= 1" :class="{ 'duplicate-disabled': count <=1 }">
        <slot name="icon">
          <a-icon type="switcher" :class="{ 'txt-orange': false }"/>
        </slot>
        {{ $t('components.labels.checkForDuplicates') }}
      </a-button>
  </a-popover>
</template>

<script>
import { getDuplicatesByColumns, getDifferenceInRows, getDifferenceInSourceAndFilteredRows, getColumnIndexes } from '../../../util/handsontable';

export default {
  name: 'DuplicationCheck',
  props: {
    hot: {
      type: Object,
      required: false,
      default: () => undefined
    },
    columns: {
      type: Array,
      required: true
    },
    columnNames: {
      type: Array,
      required: true
    },
    count: {
      type: Number,
      required: true
    },
    filteredCount: {
      type: Number,
      required: true
    },
    clearFilter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: '',
      visible: false,
      selectedColumns: [],
      filteredColumns: [],
      filteredColumnNames: [],
      rowsToHide: []
    };
  },
  watch: {
    clearFilter() {
      this.onClearAll();
    },
    filteredCount() {
      if (this.selectedColumns.length !== 0) {
        this.applyCheck();
      }
    },
    selectedColumns(items) {
      if (items.length === 0) {
        this.$emit('selectedColumns', false);
      }
    }
  },
  mounted() {
    this.filteredColumns = [...this.columns];
    this.filteredColumnNames = [...this.columnNames];
  },
  methods: {
    onCheckAll() {
      this.selectedColumns = [...this.columns];
    },
    onClearAll() {
      this.selectedColumns = [];
    },
    onSearch() {
      const cols = [];
      const colNames = [];
      this.columns.forEach((column, index) => {
        if (column.toLowerCase().includes(this.search.toLowerCase())) {
          cols.push(column);
          colNames.push(this.columnNames[index]);
        }
      });
      this.filteredColumns = cols;
      this.filteredColumnNames = colNames;
    },
    applyCheck() {
      this.$emit('selectedColumns', true);
      const duplicationKey = 'duplicationCheck';
      this.$message.loading({ content: this.$t('components.notifications.checkingDuplications'), key: duplicationKey });
      this.visible = false;
      const data = this.hot.getData();
      const dataFilteredForNulls = data.filter(item => item[0] != null);

      const columnIndexes = getColumnIndexes(this.selectedColumns, this.columns);
      const duplicates = getDuplicatesByColumns(dataFilteredForNulls, columnIndexes, 2); //normalized_submission_id and hot_id are not counted in indexes so the offset is 2.
      const rowsToHide = getDifferenceInRows(data, duplicates);

      const plugin = this.hot.getPlugin('hiddenRows');
      setTimeout(() => {
        this.hot.batch(() => {
          plugin.showRows(this.rowsToHide);
          this.rowsToHide = rowsToHide;
          plugin.hideRows(this.rowsToHide);
          this.hot.render();
          this.$message.success({ content: this.$t('components.notifications.duplicationCheckComplete'), key: duplicationKey, duration: 2 });
        });
      }, 150);
    }
  }
};

</script>
<style lang="scss" scoped>
  .duplicate-disabled {
    pointer-events: none;
  }

  .duplication-check-content {
    min-height: 175px;
    min-width: 488px;
  }

  .column-grid {
    display: grid;
    grid-template-areas: "a a a a";
    gap: 3px 35px;
    grid-auto-rows: auto;
    margin-bottom: 10px;
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0 !important;
    }
  }
</style>
